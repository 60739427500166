<template>
    <div class="button">
        <!-- https://iconscout.com/icon/linkedin-46 -->
        <a><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="-400 -400 1800 1800"><path d="M0 120c0-33.334 11.667-60.834 35-82.5C58.333 15.833 88.667 5 126 5c36.667 0 66.333 10.666 89 32 23.333 22 35 50.666 35 86 0 32-11.333 58.666-34 80-23.333 22-54 33-92 33h-1c-36.667 0-66.333-11-89-33S0 153.333 0 120zm13 875V327h222v668H13zm345 0h222V622c0-23.334 2.667-41.334 8-54 9.333-22.667 23.5-41.834 42.5-57.5 19-15.667 42.833-23.5 71.5-23.5 74.667 0 112 50.333 112 151v357h222V612c0-98.667-23.333-173.5-70-224.5S857.667 311 781 311c-86 0-153 37-201 111v2h-1l1-2v-95H358c1.333 21.333 2 87.666 2 199 0 111.333-.667 267.666-2 469z"/>
	<metadata>
		<rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:rdfs="http://www.w3.org/2000/01/rdf-schema#" xmlns:dc="http://purl.org/dc/elements/1.1/">
			<rdf:Description about="https://iconscout.com/legal#licenses" dc:title="linkedin-icon-1" dc:description="linkedin-icon-1" dc:publisher="Iconscout" dc:date="2017-06-06" dc:format="image/svg+xml" dc:language="en">
				<dc:creator>
					<rdf:Bag>
						<rdf:li>Icon Mafia</rdf:li>
					</rdf:Bag>
				</dc:creator>
			</rdf:Description>
		</rdf:RDF>
    </metadata></svg></a>
    </div>
</template>

<script>
export default {
    props: {
        colorHex: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            color: "#fff"
        }
    }
}
</script>

<style scoped>
.button {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px;
}
.button:hover {
    background-color: #0A66C2;
}
svg {
  fill: rgb(190, 190, 190);
}
svg:hover {
  fill: white;
}
</style>