<template>
    <div class="button">
        <!-- https://iconscout.com/icon/twitter-1464537 -->
        <a><svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="100%" height="100%" viewBox="-6 -1 35 24"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg></a>
    </div>
</template>

<script>
export default {
    props: {
        colorHex: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            color: "#fff"
        }
    }
}
</script>

<style>
.button {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px;
}
.button:hover {
    background-color: #1DA1F2;
}
svg {
  fill: rgb(190, 190, 190);
}
svg:hover {
  fill: white;
}
</style>